import { Box, Button, Chip, Grid, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card";
import { Customer, User } from "../../models";
import axios from "../../services/axios";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";
import Datatable from "../../components/Datatable";
import deviceColumns from "../../utils/datatable/devices.columns";
import dayjs from "dayjs";

export default function CompaniesShow() {
  const { userId } = useParams<{ userId: string }>();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [user, setUser] = useState<User>({
    name: "",
    last_name: "",
    email: "",
    phone_number: "",
    customers: [],
    userLogins: [],
    last_login: new Date(),
  } as User);

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const responseCustomer = await axios.get(`/admin/users/${userId}`);
        const customers = await axios.get(`/admin/customers`, {
          params: {
            limit: 50,
          },
        });
        setUser(responseCustomer.data as User);

        let tempCustomers = customers.data.data as Customer[];
        tempCustomers = tempCustomers.filter((customer) => {
          return !responseCustomer.data.customers?.find((item: Customer) => item.id === customer.id);
        });
        setCustomers(tempCustomers as Customer[]);
        // console.warn(customers.data);
      } catch (error) {
        ShowAlert("Error", "No se pudo obtener el cliente", "error");
      }
    };
    getUser();
  }, []);

  const onSaveCustomer = () => {
    if (!user.id) {
      ShowAlert("Error", "User ID field is missing", "error");
      return;
    }
    ShowConfirm("Guardar cliente", "¿Estás seguro de agregar este cliente?", async () => {
      try {
        await axios.put(`/admin/users/${user.id}`, user);
        ShowAlert("Cliente guardado", "Se guardó el cliente correctamente", "success");
      } catch (error) {
        ShowAlert("Error", "No se pudo guardar el cliente", "error");
      }
    });
  };

  const setCustomerData = (event: any, field: string) => {
    const value = event.target.value;
    setUser({
      ...user,
      [field]: value,
    });
  };

  const unlinkCustomer = (customer: Customer, index: number) => {
    ShowConfirm("Desvincular cliente", "¿Estás seguro de desvincular este cliente?", async () => {
      try {
        await axios.delete(`/admin/users/${user.id}/customers/${customer.id}`);
        ShowAlert("Cliente desvinculado", "Se desvinculó el cliente correctamente", "success");

        setUser({
          ...user,
          customers: user.customers?.filter((item, i) => i !== index),
        } as User);
      } catch (error) {
        ShowAlert("Error", "No se pudo desvincular el cliente", "error");
      }
    });
  };

  const linkCustomer = (customer: Customer) => {
    ShowConfirm("Vincular cliente", "¿Estás seguro de vincular este cliente?", async () => {
      try {
        await axios.post(`/admin/users/${user.id}/customers/${customer.id}`);
        ShowAlert("Cliente vinculado", "Se vinculó el cliente correctamente", "success");
        setOpenCustomerModal(false);
        setUser({
          ...user,
          customers: [...(user.customers || []), customer],
        } as User);
      } catch (error) {
        ShowAlert("Error", "No se pudo vincular el cliente", "error");
      }
    });
  };

  const resendInvite = async () => {
    ShowConfirm("Reenviar invitación", "¿Estás seguro de reenviar la invitación?", async () => {
      try {
        await axios.post(`/admin/users/${user.id}/reactivate`);
        ShowAlert("Invitación reenviada", "Se reenvió la invitación correctamente", "success");
      } catch (error) {
        ShowAlert("Error", "No se pudo reenviar la invitación", "error");
      }
    });
  };

  return (
    <Grid>
      <Card title={"Cliente"}>
        <Grid container spacing={3} marginBottom={3}>
          <Grid item xs={6}>
            <TextField
              required
              label="Nombre"
              id="name"
              name="name"
              fullWidth
              variant="standard"
              value={user.name}
              onChange={(event) => {
                setCustomerData(event, "name");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Apellido"
              id="last_name"
              name="last_name"
              fullWidth
              variant="standard"
              value={user.last_name}
              onChange={(event) => {
                setCustomerData(event, "last_name");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Email"
              id="email"
              name="email"
              fullWidth
              variant="standard"
              value={user.email}
              onChange={(event) => {
                setCustomerData(event, "email");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="phone_number"
              name="Teléfono"
              label="Teléfono"
              fullWidth
              variant="standard"
              value={user.phone_number}
              onChange={(event) => {
                setCustomerData(event, "phone_number");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              name="password"
              label="Contraseña"
              fullWidth
              variant="standard"
              value={user.password}
              onChange={(event) => {
                setCustomerData(event, "password");
              }}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => {
            onSaveCustomer();
          }}
        >
          Guardar
        </Button>
      </Card>

      <Card title={"Clientes asociados"}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => {
            setOpenCustomerModal(true);
          }}
        >
          Vincular cliente
        </Button>

        {user.customers?.map((customer: Customer, index: number) => {
          return <Chip label={customer.name} onDelete={() => unlinkCustomer(customer, index)} key={customer.name_service_desk} />;
        })}
        {!user.customers?.length && <Typography>No hay clientes asociados</Typography>}
      </Card>

      <Card title={"Acciones"}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => {
            resendInvite();
          }}
        >
          Reenviar invitación
        </Button>
      </Card>

      {userId && (
        <Datatable
          title="Dispositivos"
          endpoint={`/admin/users/${userId}/devices`}
          deleteEndpoint="/admin/devices"
          columns={deviceColumns}
          enableDelete
          selectableRows="single"
        />
      )}

      <Card title={"Registros de inicio de sesión"}>
        <div>
          {user.userLogins.map((useLogin) => {
            return <div>Fecha y hora: {dayjs(useLogin.login_datetime).format("LLL")}</div>;
          })}
        </div>
      </Card>

      <Modal
        open={openCustomerModal}
        onClose={() => setOpenCustomerModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} width={500}>
          <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={3}>
            {`Linkear un cliente a ${user.name}`}
          </Typography>
          {customers.map((customer: Customer, index: number) => {
            return <Chip label={customer.name} onClick={() => linkCustomer(customer)} key={customer.name_service_desk} />;
          })}
          {!customers.length && <Typography>No hay clientes disponibles</Typography>}
        </Box>
      </Modal>
    </Grid>
  );
}
