import { BrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/Main";
import LoginLayout from "./layouts/Login";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./reducers/auth.reducer";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state.authReducer);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(login());
    }
  }, []);

  if (!auth.loggedIn) {
    return (
      <BrowserRouter>
        <LoginLayout />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <MainLayout />
    </BrowserRouter>
  );
}

export default App;
