import { useEffect, useState } from "react";
import axios from "../../services/axios";
import MUIDataTable, { SelectableRows } from "mui-datatables";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";
import { Button, Grid, Paper } from "@mui/material";

interface Props {
  title: string;
  endpoint: string;
  columns: any;
  onRowClick?: any;
  enableEdit?: boolean;
  enableDelete?: boolean;
  selectableRows?: SelectableRows | undefined;
  deleteEndpoint?: string;
  header?: any;
  onlySuperAdmin?: boolean;
}

interface Metadata {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  previous_page_url: string;
}

interface CustomToolbarProps {
  enableEdit?: boolean;
  enableDelete?: boolean;
  selectedRows: any;
  data: any;
  setSelectedRows?: any;
  deleteEndpoint?: string;
  header?: any;
}
const CustomToolbar = ({ enableEdit, enableDelete, selectedRows, data, setSelectedRows, deleteEndpoint, header }: CustomToolbarProps) => {
  const onDelete = async () => {
    if (!deleteEndpoint) {
      ShowAlert("Error", "Delete endpoint is missing", "error");
      return;
    }
    const index = selectedRows.data[0]?.index;
    if (!data[index]) return;

    ShowConfirm("Eliminar registro", "¿Estás seguro de eliminar este registro?", async () => {
      try {
        await axios.delete(`${deleteEndpoint}/${data[index].id}`);
        ShowAlert("Registro eliminado", "El registro se eliminó correctamente", "success");
        data.splice(index, 1);
        setSelectedRows([]);
      } catch (error: any) {
        const message = error.response?.data?.message || "No se pudo eliminar el registro";
        ShowAlert("Error", message, "error");
      }
    });
  };

  return (
    <Grid container justifyContent="flex-end" marginRight={3} gap={3}>
      <Grid item spacing={3}>
        {enableEdit && (
          <Button variant="contained" color="primary" onClick={onDelete}>
            Editar
          </Button>
        )}
        {enableDelete && (
          <Button variant="contained" color="primary" onClick={onDelete}>
            Eliminar
          </Button>
        )}
        {header && header({ selectedRows, data, setSelectedRows })}
      </Grid>
    </Grid>
  );
};

export default function Datatable(props: Props) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); // [10, 15, 100]
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState<Metadata>({
    total: 0,
    per_page: 10,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  } as Metadata);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    axios
      .get(props.endpoint, {
        params: {
          page: page,
          limit: limit,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setData(response.data.data);
        setMeta(response.data.meta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper sx={{ display: "flex", flexDirection: "column" }}>
      <MUIDataTable
        title={props.title}
        data={data}
        columns={props.columns}
        options={{
          customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
            CustomToolbar({
              enableDelete: props.enableDelete,
              enableEdit: props.enableEdit,
              selectedRows,
              data,
              setSelectedRows,
              deleteEndpoint: props.deleteEndpoint,
              header: props.header,
            }),
          serverSide: true,
          rowsPerPage: limit,
          page: page - 1,
          count: meta.total,
          selectableRows: props.selectableRows,
          enableNestedDataAccess: ".",

          onTableChange: (action, tableState) => {
            switch (action) {
              case "changePage":
                setPage(tableState.page + 1);
                break;
              case "changeRowsPerPage":
                setLimit(tableState.rowsPerPage);
                setPage(1);
                break;
            }
          },

          onRowClick: (rowData, rowMeta) => {
            if (props.onRowClick) {
              props.onRowClick(rowData, rowMeta);
            }
          },
          expandableRowsOnClick: props.onRowClick ? true : false,
          textLabels: {
            body: {
              noMatch: loading ? "Cargando..." : "Lo sentimos, no se encontraron registros",
            },
            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver columnas",
              filterTable: "Filtrar tabla",
            },
            filter: {
              all: "Todos",
              title: "FILTROS",
              reset: "REINICIAR",
            },
            viewColumns: {
              title: "Mostrar columnas",
              titleAria: "Mostrar/Ocultar columnas de la tabla",
            },
            selectedRows: {
              text: "fila(s) seleccionada(s)",
              delete: "Eliminar",
            },
            pagination: {
              next: "Siguiente página",
              previous: "Página anterior",
              rowsPerPage: "Filas por página:",
              displayRows: "de",
            },
          },
        }}
      />
    </Paper>
  );
}
