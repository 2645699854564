import { Box, Button, Grid } from "@mui/material";
import Datatable from "../../components/Datatable";
import { Link, useNavigate } from "react-router-dom";
import customerColumns from "../../utils/datatable/customer.columns";
import { Add } from "@mui/icons-material";

export default function Admins() {
  const navigation = useNavigate();

  const goToCustomerDetail = (rowData: any, rowMeta: any) => {
    navigation(`/customers/${rowData[0]}`);
  };

  return (
    <Box>
      <Grid my={3} flex={1}>
        <Button variant="contained" color="primary" component={Link} to="/customers/create">
          <Add />
          Agregar Cliente
        </Button>
      </Grid>
      <Datatable
        title="Clientes"
        endpoint="/admin/customers"
        deleteEndpoint="/admin/customers"
        columns={customerColumns}
        onRowClick={goToCustomerDetail}
        enableDelete
        selectableRows="single"
      />
    </Box>
  );
}
