import { Grid, Paper, Typography } from "@mui/material";

interface Props {
  title: string;
  children: React.ReactNode;
}
export default function Card({ children, title }: Props) {
  return (
    <Grid marginBottom={4}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {children}
      </Paper>
    </Grid>
  );
}
