const customerColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nombre",
  },
  {
    name: "name_service_desk",
    label: "Service Desk ID",
  },
  {
    name: "address",
    label: "Dirección",
  },
  {
    name: "city.name",
    label: "Ciudad",
  },
  {
    name: "city.state.name",
    label: "Estado",
  },
  {
    name: "city.state.country.name",
    label: "País",
  },
  {
    name: "website",
    label: "Sitio web",
  },
];

export default customerColumns;
