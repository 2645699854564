import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";
import axios from "../../services/axios";
import Card from "../../components/Card";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
interface Customer {
  name: string;
  address: string;
  city_id: string;
  website: string;
  name_service_desk: string;
  description: string;
}

const schema = yup
  .object({
    name: yup.string().required("Este campo es requerido").max(30, "No puede tener más de 30 caracteres"),
    address: yup.string().required("Este campo es requerido").max(100, "No puede tener más de 100 caracteres"),
    city_id: yup.string().required("Este campo es requerido"),
    website: yup.string().required("Este campo es requerido").max(30, "No puede tener más de 30 caracteres"),
    name_service_desk: yup.string().required("Este campo es requerido").max(30, "No puede tener más de 30 caracteres"),
    description: yup.string().required("Este campo es requerido").max(250, "No puede tener más de 250 caracteres"),
  })
  .required();

export default function CompaniesCreate() {
  const [countries, setCountries] = useState([] as any);
  const [states, setStates] = useState([] as any);
  const [cities, setCities] = useState([] as any);

  const navigate = useNavigate();

  useEffect(() => {
    getCountries();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      city_id: "",
      website: "",
      name_service_desk: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Customer> = (data) => onSendForm(data);

  const onSendForm = (data: Customer) => {
    data.city_id = cities.find((city: any) => city.name === data.city_id).id;
    if (!data.city_id) {
      ShowAlert("Error", "Selecciona una ciudad", "error");
      return;
    }
    ShowConfirm("Guardar cliente", "¿Estás seguro de agregar este cliente?", async () => {
      try {
        await axios.post("/admin/customers", data);
        navigate(`/customers`);
        ShowAlert("Cliente guardado", "Se guardó el cliente correctamente", "success");
      } catch (error) {
        ShowAlert("Error", "No se pudo guardar el cliente", "error");
      }
    });
  };

  const getCountries = async () => {
    const response = await axios.get("/admin/countries");
    setCountries(response.data);
  };

  const getStates = async (country: any) => {
    const response = await axios.get(`/admin/countries/${country.id}/states`);
    setStates(response.data);
  };

  const getCities = async (state: any) => {
    const response = await axios.get(`/admin/states/${state.id}/cities`);
    setCities(response.data);
  };

  return (
    <Grid>
      <Card title="Nuevo cliente">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} marginBottom={3}>
            <Grid item xs={6}>
              <TextField label="Nombre de la compañía, empresa o cliente" id="name" fullWidth variant="standard" {...register("name")} />
              {errors.name && <span>{errors.name.message}</span>}
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                label="Nombre desde Service Desk"
                id="name_service_desk"
                fullWidth
                variant="standard"
                error={!!errors.name_service_desk}
                {...register("name_service_desk")}
              />
              {errors.name_service_desk && <span>{errors.name_service_desk.message}</span>}
            </Grid>
            <Grid item xs={12}>
              <TextField required label="Descripicón" id="name" fullWidth variant="standard" {...register("description")} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                label="Dirección de la compañía, empresa o cliente"
                fullWidth
                autoComplete="shipping address-line"
                variant="standard"
                {...register("address")}
              />
              {errors.address && <span>{errors.address.message}</span>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={countries}
                autoComplete={false}
                getOptionLabel={(option: any) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onChange={(_, value) => {
                  getStates(value);
                }}
                renderInput={(params) => <TextField {...params} required label="País" fullWidth variant="standard" autoComplete="off" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={states}
                autoComplete={false}
                getOptionLabel={(option: any) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onChange={(_, value) => {
                  getCities(value);
                }}
                renderInput={(params) => <TextField {...params} required label="Estado" fullWidth variant="standard" autoComplete="off" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={cities}
                autoComplete={false}
                getOptionLabel={(option: any) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                itemProp="id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Ciudad"
                    fullWidth
                    variant="standard"
                    autoComplete="off"
                    property="id"
                    {...register("city_id")}
                  />
                )}
              />
              {errors.city_id && <span>{errors.city_id.message}</span>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField required id="website" label="Sitio web" fullWidth autoComplete="website" variant="standard" {...register("website")} />
              {errors.website && <span>{errors.website.message}</span>}
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" type="submit">
            Agregar
          </Button>
        </form>
      </Card>
    </Grid>
  );
}
