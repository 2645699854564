import Swal, { SweetAlertIcon } from "sweetalert2";

export function ShowAlert(title: string, description: string, type: SweetAlertIcon) {
  Swal.fire(title, description, type);
}

export function ShowConfirm(
  title: string,
  description: string,
  callback: Function,
  confirmButtonText: string = "Aceptar",
  cancelButtonText: string = "Cancelar"
) {
  Swal.fire({
    title: title,
    text: description,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}
