import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import LogIn from "../pages/Login";
import NotFound from "../pages/404";
import Activate from "../pages/Activate/activateAdmin";
import ActivateUser from "../pages/Activate/activateUser";
import RecoverPassword from "../pages/RecoverPassword/recoverPassword";
import DownloadApp from "../pages/DownloadApp/index";

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.cyberpeace.tech/" target={'_blank'}>
        Cyberpeace - SOC360
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function LoginLayout() {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Routes>
          <Route path="/" element={<LogIn />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/activate/user" element={<ActivateUser />} />
          <Route path="/recover-password" element={<RecoverPassword />} />
          <Route path="/download" element={<DownloadApp />} />
          {/* 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
