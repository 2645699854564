import { createSlice } from "@reduxjs/toolkit";

// Create Auth Reducer
const authReducer = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loggedIn: false,
    loading: true,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    login: (state) => {
      state.loggedIn = true;
      state.loading = false;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.loading = false;
      state.user = null;
    },
  },
});

// Export Actions
export const { login, setUser, logout } = authReducer.actions;

// Export Reducer
export default authReducer.reducer;
