import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL ?? "http://localhost:5000/api/v1";

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

instance.interceptors.response.use((response) => {
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  return response;
}, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  return Promise.reject(error);
});

export const axiosPublic = axios.create({
  baseURL: baseURL,
});

export default instance;
