import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import userColumns from "../../utils/datatable/user.columns";

export default function Admins() {
  const navigation = useNavigate();

  const goToCustomerDetail = (rowData: any, rowMeta: any) => {
    navigation(`/users/${rowData[0]}`);
  };

  return (
    <Box>
      <Grid my={3} flex={1}>
      </Grid>
      <Datatable
        title="Usuarios"
        endpoint="/admin/users"
        deleteEndpoint="/admin/users"
        columns={userColumns}
        onRowClick={goToCustomerDetail}
        enableDelete
        selectableRows="single"
      />
    </Box>
  );
}
