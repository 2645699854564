import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "../../services/axios";
import { Customer } from "../../models";
import Card from "../../components/Card";
import dayjs from "dayjs";

export default function Dashboard() {
  const [customer, setCustomer] = useState<Customer[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const users = await axios.get("/admin/customers", {
          params: {
            limit: 50,
          },
        });
        setCustomer(users.data.data);
      } catch (error) {
        alert("Error");
      }
    };

    getData();
  }, []);
  return (
    <div>
      <Typography variant="h4">Dashboard</Typography>

      <Card title={`Clientes (${customer.length})`}>
        {customer.map((customer) => {
          return (
            <Accordion>
              <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                {customer.name} ({customer.users?.length} usuarios)
              </AccordionSummary>
              <AccordionDetails>
                {customer.users?.map((user) => {
                  return (
                    <div>
                      <p>
                        <span className="font-bold">
                          {user.name} {user.last_name}{" "}
                        </span>
                        (<a href={`mailto:${user.email}`}>{user.email}</a>)
                      </p>
                      {user.userLogins.length > 0 && (
                        <div className="ml-4">
                          Inicios de sesión:
                          {user.userLogins.map((login) => {
                            return <div className="ml-4">{dayjs(login.login_datetime).format("LLL")}</div>;
                          })}
                        </div>
                      )}
                      {user.userLogins.length == 0 && (
                        <div className="ml-4">
                          Inicios de sesión:
                          {user.last_login && <div className="ml-4">{dayjs(user.last_login).format("LLL")}</div>}
                          {!user.last_login && <div className="ml-4">Sin registro</div>}
                        </div>
                      )}
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Card>
    </div>
  );
}
