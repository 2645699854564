import { useEffect, useState } from "react";
import AppBar from "../components/AppBar";
import Drawer from "../components/Drawer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, CssBaseline, Grid, Toolbar } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Companies from "../pages/Customers";
import CustomersCreate from "../pages/Customers/create";
import NotFound from "../pages/404";
import CompaniesShow from "../pages/Customers/show";
import Users from "../pages/Users";
import UsersShow from "../pages/Users/show";
import { useDispatch } from "react-redux";
import axios from "../services/axios";
import { setUser } from "../reducers/auth.reducer";
import Admins from "../pages/Admins";
import UsersCreate from "../pages/Users/create";

export default function MainLayout() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    axios.get("/admin/me").then((res) => {
      dispatch(setUser(res.data));
    });
  }, []);

  const mdTheme = createTheme();

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar open={open} toggleDrawer={toggleDrawer} />
        <Drawer open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container>
            <Grid spacing={3} marginTop={5}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/users" element={<Users />} />
                <Route path="/customers" element={<Companies />} />
                <Route path="/customers/create" element={<CustomersCreate />} />
                <Route path="/customers/:customerId" element={<CompaniesShow />} />
                <Route path="/users/create" element={<UsersCreate />} />
                <Route path="/users/:userId" element={<UsersShow />} />
                <Route path="/admins" element={<Admins />} />
                {/* 404 */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
