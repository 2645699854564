import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { axiosPublic } from "../../services/axios";
import { ShowAlert } from "../../utils/alerts";
import { useDispatch } from "react-redux";
import { login } from "../../reducers/auth.reducer";

export default function LogIn() {
  const dispatch = useDispatch();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axiosPublic
      .post("/admin/login", {
        email: data.get("email"),
        password: data.get("password"),
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        dispatch(login(res.data.user));
      })
      .catch((err) => {
        ShowAlert("Error", "Credenciales incorrectas", "error");
      });
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Iniciar sesión
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField margin="normal" required fullWidth id="email" label="Email" name="email" autoComplete="email" type="email" autoFocus />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Entrar
        </Button>
      </Box>
    </Box>
  );
}
