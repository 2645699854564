const retportColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "date",
    label: "Date",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "service.name",
    label: "Service",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "url",
    label: "URL",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export default retportColumns;
