const ticketColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "ticketType.service.name",
    label: "Servicio",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "ticketType.name",
    label: "Tipo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "description",
    label: "Descripción",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export default ticketColumns;
