const customerColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nombre",
  },
  {
    name: "email",
    label: "Email",
  },
];

export default customerColumns;
