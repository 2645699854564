import { Autocomplete, Box, Button, Chip, Grid, Input, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../services/axios";
import Datatable from "../../components/Datatable";
import userColumns from "../../utils/datatable/user.columns";
import { Customer, Notification, Reports, Service, Ticket, User } from "../../models";
import Card from "../../components/Card";
import ticketColumns from "../../utils/datatable/ticket.columns";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import reportColumns from "../../utils/datatable/report.columns";

export default function CompaniesShow() {
  const navigation = useNavigate();

  const { customerId } = useParams<{ customerId: string }>();
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [unhiredServices, setUnhiredServices] = useState<Service[]>([]);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [customer, setCustomer] = useState<Customer>({
    name: "",
    name_service_desk: "",
    description: "",
    address: "",
    city_id: null,
    website: "",
  } as Customer);

  const [ticket, setTicket] = useState({
    description: "",
    title: "",
  } as Ticket);

  const [user, setUser] = useState<User>({
    name: "",
    last_name: "",
    email: "",
    phone_number: "",
  } as User);

  const [report, setReport] = useState({
    date: null,
    files: [],
    service_id: null,
  } as Reports);

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [notification, setNotifications] = useState<Notification>({
    title: "",
    description: "",
  });

  useEffect(() => {
    const getCustomer = async () => {
      try {
        const responseCustomer = await axios.get(`/admin/customers/${customerId}`, {
          params: {
            limit: 50,
          },
        });
        setCustomer(responseCustomer.data as Customer);
        const responseServices = await axios.get(`/admin/services`);

        const unhiredServices = responseServices.data.filter((service: Service) => {
          return !responseCustomer.data.services.some((customerService: Service) => customerService.id === service.id);
        });
        setUnhiredServices(unhiredServices as Service[]);
      } catch (error) {
        ShowAlert("Error", "No se pudo obtener el cliente", "error");
      }
    };
    getCustomer();
  }, []);

  const getTicketStatuses = async (serviceId: number) => {
    try {
      const response = await axios.get(`/admin/services/${serviceId}/ticketstatuses`);
      setTicketStatuses(response.data);
    } catch (error) {
      ShowAlert("Error", "No se pudo obtener los tipos de ticket", "error");
    }
  };

  const getCountries = async () => {
    const response = await axios.get("/admin/countries");
    setCountries(response.data);
  };

  const getStates = async (country: any) => {
    const response = await axios.get(`/admin/countries/${country.id}/states`);
    setStates(response.data);
  };

  const getCities = async (state: any) => {
    const response = await axios.get(`/admin/states/${state.id}/cities`);
    setCities(response.data);
  };

  const setUserData = (event: any, field: string) => {
    const value = event.target.value;
    setUser({
      ...user,
      [field]: value,
    });
  };

  const setCustomerData = (event: any, field: string) => {
    const value = event.target.value;
    setCustomer({
      ...customer,
      [field]: value,
    });
  };

  const setNotificationData = (event: any, field: string) => {
    const value = event.target.value;
    setNotifications({
      ...notification,
      [field]: value,
    });
  };

  const setTicketData = (event: any, field: string) => {
    const value = event.target.value;
    setTicket({
      ...ticket,
      [field]: value,
    });
  };

  const onSaveCustomer = () => {
    ShowConfirm("Guardar cliente", "¿Estás seguro de agregar este cliente?", async () => {
      try {
        await axios.put(`/admin/customers/${customer.id}`, customer);
        ShowAlert("Cliente guardado", "Se guardó el cliente correctamente", "success");
      } catch (error) {
        ShowAlert("Error", "No se pudo guardar el cliente", "error");
      }
    });
  };

  const onSendNotification = () => {
    ShowConfirm("Enviar notificación", "¿Estás seguro de enviar esta notificación?", async () => {
      try {
        await axios.post(`/admin/customers/${customer.id}/notification`, notification);
        ShowAlert("Notificación enviada", "Se envió la notificación correctamente", "success");
        setOpenNotificationModal(false);
      } catch (error) {
        ShowAlert("Error", "No se pudo enviar la notificación", "error");
      }
    });
  };

  const onSendTicket = () => {
    ShowConfirm("Enviar ticket", "¿Estás seguro de enviar este ticket?", async () => {
      try {
        await axios.post(`/admin/customers/${customer.id}/ticket`, ticket);
        ShowAlert("Ticket enviado", "Se envió el ticket correctamente", "success");
        setOpenTicketModal(false);
      } catch (error) {
        ShowAlert("Error", "No se pudo enviar el ticket", "error");
      }
    });
  };

  const onSendUser = () => {
    ShowConfirm("Guardar usuario", "¿Estás seguro de agregar este usuario?", async () => {
      try {
        user.customer_id = customer.id;
        await axios.post("/admin/users", user);
        ShowAlert("Cliente guardado", "Se guardó el cliente correctamente", "success");
        setOpenUserModal(false);
      } catch (error) {
        ShowAlert("Error", "No se pudo guardar el cliente", "error");
      }
    });
  };

  const onSendReport = () => {
    ShowConfirm("Guardar reporte", "¿Estás seguro de agregar este reporte?", async () => {
      if (!report.date || !report.service_id) return ShowAlert("Error", "No se pudo guardar el reporte", "error");
      try {
        const formData = new FormData();
        for (const file of report.files) {
          formData.append("files[]", file);
        }
        formData.append("date", report.date.toISOString());
        formData.append("service_id", report.service_id.toString());
        await axios.post(`/admin/customers/${customer.id}/reports`, formData);
        ShowAlert("Reporte guardado", "Se guardó el reporte correctamente", "success");
        setOpenReportModal(false);
      } catch (error) {
        console.error(error);
        ShowAlert("Error", "No se pudo guardar el reporte", "error");
      }
    });
  };

  const handleDeleteService = (service: Service, index: number) => {
    try {
      ShowConfirm("Eliminar servicio", "¿Estás seguro de eliminar este servicio?", async () => {
        await axios.delete(`/admin/customers/${customer.id}/services/${service.id}`);
        // Update services list
        const services = customer.services;
        services.splice(index, 1);
        setCustomer({
          ...customer,
          services: services,
        } as Customer);
        setUnhiredServices([...unhiredServices, service]);
        ShowAlert("Servicio eliminado", "El servicio se eliminó correctamente", "success");
      });
    } catch (error) {
      ShowAlert("Error", "No se pudo eliminar el servicio", "error");
    }
  };

  const handleHireService = (service: Service, index: number) => {
    try {
      ShowConfirm("Contratar servicio", "¿Estás seguro de contratar este servicio?", async () => {
        await axios.post(`/admin/customers/${customer.id}/services/${service.id}`);
        // Update services list
        const services = customer.services;
        services.push(service);
        setCustomer({
          ...customer,
          services: services,
        } as Customer);

        // Update unhired services list
        const unhiredServicesUpdated = [...unhiredServices];
        unhiredServicesUpdated.splice(index, 1);
        setUnhiredServices(unhiredServicesUpdated);
        ShowAlert("Servicio contratado", "El servicio se contrató correctamente", "success");
        setOpenServiceModal(false);
      });
    } catch (error) {
      ShowAlert("Error", "No se pudo contratar el servicio", "error");
    }
  };

  const goToUserDetails = (rowData: any) => {
    navigation(`/users/${rowData[0]}`);
  };

  return (
    <Grid>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => {
          setOpenNotificationModal(true);
        }}
        style={{ marginBottom: 20 }}
      >
        Enviar notificación
      </Button>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => {
          setOpenTicketModal(true);
        }}
        style={{ marginBottom: 20, marginLeft: 20 }}
      >
        Generar Ticket
      </Button>

      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => {
          setOpenServiceModal(true);
        }}
        style={{ marginBottom: 20, marginLeft: 20 }}
      >
        Añadir Servicio
      </Button>

      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => {
          setOpenUserModal(true);
        }}
        style={{ marginBottom: 20, marginLeft: 20 }}
      >
        Añadir Usuario
      </Button>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => {
          setOpenReportModal(true);
        }}
        style={{ marginBottom: 20, marginLeft: 20 }}
      >
        Añadir Reporte
      </Button>

      <Card title={"Cliente"}>
        <Grid container spacing={3} marginBottom={3}>
          <Grid item xs={6}>
            <TextField
              required
              label="Nombre de la compañía, empresa o cliente"
              id="name"
              name="name"
              fullWidth
              variant="standard"
              value={customer.name}
              onChange={(event) => {
                setCustomerData(event, "name");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Nombre desde Service Desk"
              id="name_service_desk"
              name="name_service_desk"
              fullWidth
              variant="standard"
              value={customer.name_service_desk}
              onChange={(event) => {
                setCustomerData(event, "name_service_desk");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Descripicón"
              id="name"
              name="name"
              fullWidth
              variant="standard"
              value={customer.description}
              onChange={(event) => {
                setCustomerData(event, "description");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              name="address"
              label="Dirección de la compañía, empresa o cliente"
              fullWidth
              autoComplete="shipping address-line"
              variant="standard"
              value={customer.address}
              onChange={(event) => {
                setCustomerData(event, "address");
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={countries}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(_, value) => {
                getStates(value);
              }}
              renderInput={(params) => <TextField {...params} required label="País" fullWidth variant="standard" autoComplete="off" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={states}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(_, value) => {
                getCities(value);
              }}
              renderInput={(params) => <TextField {...params} required label="Estado" fullWidth variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={cities}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(_, value) => {
                setCustomerData(value, "city_id");
              }}
              renderInput={(params) => <TextField {...params} required label="Ciudad" fullWidth variant="standard" autoComplete="off" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="website"
              name="website"
              label="Sitio web"
              fullWidth
              autoComplete="website"
              variant="standard"
              value={customer.website}
              onChange={(event) => {
                setCustomerData(event, "website");
              }}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => {
            onSaveCustomer();
          }}
        >
          Guardar
        </Button>
      </Card>

      {!!customer.id && (
        <Grid>
          <Grid marginBottom={3}>
            <Datatable
              title="Usuarios"
              endpoint={`/admin/customers/${customer.id}/users`}
              columns={userColumns}
              onRowClick={goToUserDetails}
              selectableRows={"single"}
              enableDelete
              deleteEndpoint="/admin/users"
            />
          </Grid>
          <Grid marginBottom={3}>
            <Datatable title="Tickets" endpoint={`/admin/customers/${customer.id}/tickets`} columns={ticketColumns} />
          </Grid>
          <Grid marginBottom={3}>
            <Datatable title="Reportes" endpoint={`/admin/customers/${customer.id}/reports`} columns={reportColumns} />
          </Grid>
          <Card title={"Servicios contratados"}>
            {customer.services.map((service: Service, index: number) => {
              return <Chip label={service.name} onDelete={() => handleDeleteService(service, index)} key={service.slug} />;
            })}
            {!customer.services.length && (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => {
                  setOpenServiceModal(true);
                }}
                style={{ marginLeft: 20 }}
              >
                Agregar servicio
              </Button>
            )}
          </Card>
        </Grid>
      )}

      <Modal
        open={openNotificationModal}
        onClose={() => setOpenNotificationModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} width={500}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`Enviar notificación a ${customer.name}`}
          </Typography>
          <Grid item xs={12}>
            <TextField
              required
              id="title"
              name="title"
              label="Título"
              fullWidth
              autoComplete="shipping country"
              variant="standard"
              onChange={(event) => {
                setNotificationData(event, "title");
              }}
            />
          </Grid>
          <Grid item xs={12} marginBottom={3}>
            <TextField
              required
              id="description"
              name="description"
              label="Descripción"
              fullWidth
              autoComplete="website"
              variant="standard"
              onChange={(event) => {
                setNotificationData(event, "description");
              }}
            />
          </Grid>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={() => {
              onSendNotification();
            }}
            style={{ marginBottom: 20 }}
          >
            Enviar notificación
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openTicketModal}
        onClose={() => setOpenTicketModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} width={500}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`Crear Ticket para ${customer.name}`}
          </Typography>
          <Grid item xs={12}>
            <Autocomplete
              options={customer.services}
              getOptionLabel={(option: any) => option.name}
              onChange={(event, value: any) => {
                getTicketStatuses(value.id);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} required label="Servicio" fullWidth variant="standard" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={ticketStatuses}
              getOptionLabel={(option: any) => option.service_desk_name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(_, value: any) => {
                setTicket({
                  ...ticket,
                  ticket_status_id: value.id,
                });
              }}
              renderInput={(params) => <TextField {...params} required label="Tipo de incidencia" fullWidth variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} marginBottom={3}>
            <TextField
              required
              id="title"
              name="title"
              label="Titulo del ticket"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              onChange={(event) => {
                setTicketData(event, "title");
              }}
            />
          </Grid>
          <Grid item xs={12} marginBottom={3}>
            <TextField
              required
              id="description"
              name="description"
              label="Detalles del ticket"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              onChange={(event) => {
                setTicketData(event, "description");
              }}
            />
          </Grid>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={() => {
              onSendTicket();
            }}
            style={{ marginBottom: 20 }}
          >
            Generar Ticket
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openServiceModal}
        onClose={() => setOpenServiceModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} width={500}>
          <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={3}>
            {`Agregar servicio a ${customer.name}`}
          </Typography>
          {unhiredServices.map((service: Service, index: number) => {
            return <Chip label={service.name} onClick={() => handleHireService(service, index)} key={service.slug} />;
          })}
          {!unhiredServices.length && (
            <Typography id="modal-modal-title" textAlign={"center"}>
              Todos los servicios han sido contratados
            </Typography>
          )}
        </Box>
      </Modal>

      <Modal
        open={openUserModal}
        onClose={() => setOpenUserModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} width={500}>
          <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={3}>
            {`Agregar Usuario a ${customer.name}`}
          </Typography>
          <Grid container spacing={3} marginBottom={3}>
            <Grid item md={6} xs={12}>
              <TextField
                required
                label="Nombre"
                id="name"
                name="name"
                fullWidth
                variant="standard"
                onChange={(event) => {
                  setUserData(event, "name");
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                required
                label="Apellidos"
                id="last_name"
                name="last_name"
                fullWidth
                variant="standard"
                onChange={(event) => {
                  setUserData(event, "last_name");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="email"
                variant="standard"
                onChange={(event) => {
                  setUserData(event, "email");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="phone_number"
                name="phone_number"
                label="Teléfono"
                fullWidth
                variant="standard"
                onChange={(event) => {
                  setUserData(event, "phone_number");
                }}
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={onSendUser}>
            Agregar
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openReportModal}
        onClose={() => setOpenReportModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} width={500}>
          <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={3}>
            {`Agregar Reporte a ${customer.name}`}
          </Typography>
          <Grid container spacing={3} marginBottom={3}>
            <Grid item md={12} xs={12}>
              <Input
                type="file"
                inputProps={{ accept: "application/pdf", multiple: true }}
                onChange={(event: any) => {
                  setReport({
                    ...report,
                    files: event.target.files,
                  } as Reports);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={customer.services}
                getOptionLabel={(option: any) => option.name}
                onChange={(event, value: any) => {
                  setReport({
                    ...report,
                    service_id: value.id,
                  });
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} required label="Servicio" fullWidth variant="standard" />}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <DatePicker
                label="Fecha de reporte"
                onChange={(value: any) => {
                  const date = dayjs(value).toDate();
                  setReport({
                    ...report,
                    date: date,
                  });
                }}
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={onSendReport} disabled={!report.date || !report.files.length || !report.service_id}>
            Agregar
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
}
