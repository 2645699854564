import { Grid } from "@mui/material";
export default function DownloadApp() {
  return (
    <Grid>
      <Grid>
        <h1>Descarga la app</h1>
        <p>Descarga la app de SOC360 para Android y iOS y comienza a utilizarla.</p>
      </Grid>
      <Grid container justifyContent={"space-between"}>
        <a href="https://play.google.com/store/apps/details?id=tech.cyberpeace.soc360" target="_blank" rel="noopener noreferrer">
          <img src="/images/google-badge.png" alt="" />
        </a>
        <a href="https://apps.apple.com/mx/app/cyberpeace-soc360/id6446254537" target="_blank" rel="noopener noreferrer">
          <img src="/images/apple-badge.png" alt="" />
        </a>
      </Grid>
    </Grid>
  );
}
