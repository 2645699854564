const deviceColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "device_name",
    label: "Nombre del dispositivo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "device_id",
    label: "ID del dispositivo",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "onesignal_id",
    label: "ID de OneSignal",
    options: {
      filter: true,
      sort: true,
    },
  },
];

export default deviceColumns;