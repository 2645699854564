import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";
import axios from "../../services/axios";
import Card from "../../components/Card";
import { useNavigate } from "react-router-dom";

interface Customer {
  name: string;
  address: string;
  city_id: number | null;
  website: string;
  name_service_desk: string;
}

export default function UsersCreate() {
  const [customer, setCustomer] = useState<Customer>({
    name: "",
    description: "",
    address: "",
    city_id: null,
    website: "",
    name_service_desk: "",
  } as Customer);
  const [countries, setCountries] = useState([] as any);
  const [states, setStates] = useState([] as any);
  const [cities, setCities] = useState([] as any);

  const navigate = useNavigate();

  useEffect(() => {
    getCountries();
  }, []);

  const setCustomerData = (event: any, field: string) => {
    const value = event.target.value;
    setCustomer({
      ...customer,
      [field]: value,
    });
  };

  const onSendForm = () => {
    ShowConfirm("Guardar cliente", "¿Estás seguro de agregar este cliente?", async () => {
      try {
        await axios.post("/admin/customers", customer);
        setCustomer({} as Customer);
        navigate(`/customers`);
        ShowAlert("Cliente guardado", "Se guardó el cliente correctamente", "success");
      } catch (error) {
        ShowAlert("Error", "No se pudo guardar el cliente", "error");
      }
    });
  };

  const getCountries = async () => {
    const response = await axios.get("/admin/countries");
    setCountries(response.data);
  };

  const getStates = async (country: any) => {
    const response = await axios.get(`/admin/countries/${country.id}/states`);
    setStates(response.data);
  };

  const getCities = async (state: any) => {
    const response = await axios.get(`/admin/states/${state.id}/cities`);
    setCities(response.data);
  };

  return (
    <Grid>
      <Card title="Nuevo cliente">
        <Grid container spacing={3} marginBottom={3}>
          <Grid item xs={6}>
            <TextField
              required
              label="Nombre de la compañía, empresa o cliente"
              id="name"
              name="name"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setCustomerData(event, "name");
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Nombre desde Service Desk"
              id="name_service_desk"
              name="name_service_desk"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setCustomerData(event, "name_service_desk");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Descripicón"
              id="name"
              name="name"
              fullWidth
              variant="standard"
              onChange={(event) => {
                setCustomerData(event, "description");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address"
              name="address"
              label="Dirección de la compañía, empresa o cliente"
              fullWidth
              autoComplete="shipping address-line"
              variant="standard"
              onChange={(event) => {
                setCustomerData(event, "address");
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={countries}
              autoComplete={false}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(_, value) => {
                getStates(value);
              }}
              renderInput={(params) => <TextField {...params} required label="País" fullWidth variant="standard" autoComplete="off" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={states}
              autoComplete={false}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(_, value) => {
                getCities(value);
              }}
              renderInput={(params) => <TextField {...params} required label="Estado" fullWidth variant="standard" autoComplete="off" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={cities}
              autoComplete={false}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(_, value) => {
                setCustomer({
                  ...customer,
                  city_id: value.id,
                });
              }}
              renderInput={(params) => <TextField {...params} required label="Ciudad" fullWidth variant="standard" autoComplete="off" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="website"
              name="website"
              label="Sitio web"
              fullWidth
              autoComplete="website"
              variant="standard"
              onChange={(event) => {
                setCustomerData(event, "website");
              }}
            />
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" onClick={onSendForm}>
          Agregar
        </Button>
      </Card>
    </Grid>
  );
}
