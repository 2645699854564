import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import Datatable from "../../components/Datatable";
import columns from "../../utils/datatable/admin.columns";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { Admin } from "../../models";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";
import axios from "../../services/axios";

export default function Users() {
  const [openAddAdminModal, setOpenAddAdminModal] = useState(false);
  const [admin, setAdmin] = useState({
    name: "",
    email: "",
  } as Admin);

  const goToCustomerDetail = (rowData: any, rowMeta: any) => {
    setOpenAddAdminModal(true);
  };

  const setUserData = (event: any, field: string) => {
    const value = event.target.value;
    setAdmin({
      ...admin,
      [field]: value,
    });
  };

  const onSendAdmin = async () => {
    ShowConfirm("Guardar administrador", "¿Estás seguro de agregar este administrador?", async () => {
      try {
        await axios.post("/admin/admins", admin);
        ShowAlert("Administrador guardado", "Se guardó el administrador correctamente", "success");
        setOpenAddAdminModal(false);
      } catch (error) {
        ShowAlert("Error", "No se pudo guardar el administrador", "error");
      }
    });
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box>
      <Grid my={3} flex={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenAddAdminModal(true);
          }}
        >
          <Add />
          Agregar Administrador
        </Button>
      </Grid>

      <Datatable
        title="Administradores"
        endpoint="/admin/admins"
        columns={columns}
        onRowClick={goToCustomerDetail}
        selectableRows="single"
        header={Header}
        deleteEndpoint="/admin/admins"
        enableDelete
        onlySuperAdmin
      />

      <Modal
        open={openAddAdminModal}
        onClose={() => setOpenAddAdminModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal} width={500}>
          <Typography id="modal-modal-title" variant="h6" component="h2" marginBottom={3}>
            {`Agregar Administrador`}
          </Typography>
          <Grid container spacing={3} marginBottom={3}>
            <Grid item md={12} xs={12}>
              <TextField
                required
                label="Nombre"
                id="name"
                name="name"
                fullWidth
                variant="standard"
                onChange={(event) => {
                  setUserData(event, "name");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="email"
                name="email"
                label="Email"
                fullWidth
                autoComplete="email"
                variant="standard"
                onChange={(event) => {
                  setUserData(event, "email");
                }}
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" onClick={onSendAdmin}>
            Agregar
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}

function Header({ selectedRows, data, setSelectedRows }: any) {
  const restorePassword = async () => {
    console.warn(selectedRows);

    if (!selectedRows.data[0]) return;
    const index = selectedRows.data[0]?.index;
    if (!data[index]) return;

    ShowConfirm("Reestablecer contraseña", "¿Estás seguro de reestablecer la contraseña de este usuario?", async () => {
      try {
        await axios.post(`/admin/admins/${data[index].id}/reactivate`);
        ShowAlert("Contraseña reestablecida", "Se reestableció la contraseña correctamente", "success");
        setSelectedRows([]);
      } catch (error) {
        ShowAlert("Error", "No se pudo reestablecer la contraseña", "error");
      }
    });
  };

  return (
    <Button variant="contained" color="primary" onClick={restorePassword} style={{ marginLeft: 10 }}>
      Reestablecer contraseña
    </Button>
  );
}
