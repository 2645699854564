import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/auth.reducer";
// Create Store
const store = configureStore({
  reducer: {
    authReducer,
  },
});

// Export Store
export default store;
