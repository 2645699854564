import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { axiosPublic } from "../../services/axios";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";

export default function RecoverPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = React.useState<any>({});

  React.useEffect(() => {
    localStorage.removeItem("token");

    const getTokenData = async () => {
      const token = searchParams.get("token");

      if (!token) navigate("/");

      if (token) {
        try {
          const response = await axiosPublic.get("/user/activate", {
            params: {
              token,
            },
          });

          setUser(response.data);
        } catch (error) {
          ShowAlert("Error", "Token invalido o expirado", "error");
        }
      }
    };
    getTokenData();
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    ShowConfirm("Guardar contraseña", "¿Estás seguro de guardar esta nueva contraseña?", async () => {
      try {
        await axiosPublic.post("/user/activate", {
          token: searchParams.get("token"),
          password: data.get("password"),
          confirm_password: data.get("confirm_password"),
        });

        ShowAlert("Contraseña guardada correctamente", "Se ha guardado la nueva contraseña correctamente", "success");
        navigate("/download");
      } catch (error: any) {
        let message = "No se pudo guardar la contraseña";

        if (error.response?.data?.messages.errors.length > 0) {
          message = error.response.data.messages.errors[0].message;
        }

        ShowAlert("Error", message, "error");
      }
    });
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Recuperar contraseña
      </Typography>
      <TextField margin="normal" value={user.name} required fullWidth id="name" disabled name="name" autoComplete="name" autoFocus />
      <TextField margin="normal" value={user.email} required fullWidth id="email" disabled name="email" autoComplete="email" />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirm_password"
          label="Confirmar contraseña"
          type="password"
          id="confirm_password"
          autoComplete="current-password"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Guardar contraseña
        </Button>
      </Box>
    </Box>
  );
}
