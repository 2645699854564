import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { axiosPublic } from "../../services/axios";
import { ShowAlert, ShowConfirm } from "../../utils/alerts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordRegex } from "../../utils/regex";

export default function ActivateUser() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>({
    minLenght: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialCharacter: false,
    match: false,
  });

  React.useEffect(() => {
    const getTokenData = async () => {
      const token = searchParams.get("token");

      if (!token) navigate("/");

      if (token) {
        try {
          const response = await axiosPublic.get("/user/activate", {
            params: {
              token,
            },
          });

          setUser(response.data);
        } catch (error) {
          ShowAlert("Error", "Token invalido o expirado", "error");
        }
      }
    };
    getTokenData();
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validatePassword()) return;

    const data = new FormData(event.currentTarget);

    ShowConfirm("Activar usuario", "¿Estás seguro de activar este usuario?", async () => {
      try {
        await axiosPublic.post("/user/activate", {
          token: searchParams.get("token"),
          password: data.get("password"),
          confirm_password: data.get("confirm_password"),
        });

        ShowAlert("Usuario activado", "Se activó el usuario correctamente", "success");
        navigate("/download");
      } catch (error: any) {
        let message = "No se pudo activar el usuario";

        if (error.response?.data?.messages.errors.length > 0) {
          message = error.response.data.messages.errors[0].message;
        }

        ShowAlert("Error", message, "error");
      }
    });
  };

  const onBlurPassword = () => {
    const password = (document.getElementById("password") as HTMLInputElement).value;
    const confirm_password = (document.getElementById("confirm_password") as HTMLInputElement).value;

    const minLenght = password.length < 8;
    const uppercase = password.match(/[A-Z]/) ? false : true;
    const lowercase = password.match(/[a-z]/) ? false : true;
    const number = password.match(/[0-9]/) ? false : true;
    const specialCharacter = password.match(/[.@$!%*?&_-]/) ? false : true;
    const match = password !== confirm_password;

    setErrors({
      minLenght,
      uppercase,
      lowercase,
      number,
      specialCharacter,
      match,
    });
  };

  const validatePassword = () => {
    const password = (document.getElementById("password") as HTMLInputElement).value;
    const confirm_password = (document.getElementById("confirm_password") as HTMLInputElement).value;

    if (!password.match(PasswordRegex.pattern)) {
      ShowAlert(
        "Error",
        "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial (@, $, !, %, *, ?, &, _ o -)",
        "error"
      );
      return false;
    }

    if (password !== confirm_password) {
      ShowAlert("Error", "Las contraseñas no coinciden", "error");
      return false;
    }

    return true;
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Activar usuario
      </Typography>
      <TextField margin="normal" value={user.name} required fullWidth id="name" disabled name="name" autoComplete="name" autoFocus />
      <TextField margin="normal" value={user.email} required fullWidth id="email" disabled name="email" autoComplete="email" />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          autoFocus
        />
        {errors.minLenght && <p>La contraseña debe tener al menos 8 caracteres</p>}
        {errors.uppercase && <p>La contraseña debe tener al menos una mayúscula</p>}
        {errors.lowercase && <p>La contraseña debe tener al menos una minúscula</p>}
        {errors.number && <p>La contraseña debe tener al menos un número</p>}
        {errors.specialCharacter && <p>La contraseña debe tener al menos un caracter especial</p>}
        {errors.match && <p>Las contraseñas no coinciden</p>}
        <TextField
          margin="normal"
          required
          fullWidth
          name="confirm_password"
          label="Confirmar contraseña"
          type="password"
          id="confirm_password"
          autoComplete="current-password"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Activar usuario
        </Button>
      </Box>
    </Box>
  );
}
